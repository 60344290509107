<template>
  <v-row>
    <v-col cols="12">
      <v-tabs v-model="activeTab" hide-slider>
        <v-tab class="admin-mails__tab">
          <v-badge :content="unreadRegisters" overlap color="#FFA000" offset-y="10" offset-x="-5" :value="unreadRegisters !== 0">
            <v-icon left color="#018ABE">mdi-email</v-icon>Регистрации
          </v-badge>
        </v-tab>
        <v-tab class="admin-mails__tab">
          <v-badge :content="unreadOrders" overlap color="#FFA000" offset-y="10" offset-x="-5" :value="unreadOrders !== 0">
            <v-icon left color="#018ABE">mdi-swap-horizontal-bold</v-icon>Заказы
          </v-badge>
        </v-tab>
      </v-tabs>
    </v-col>
    <v-col cols="12">
      <v-tabs-items v-model="activeTab" style="overflow: visible" touchless>
        <v-tab-item>
          <v-data-table
              :headers="headers"
              :items="registers"
              class="elevation-1 admin-table"
              :footer-props="{'items-per-page-text':'Регистраций на странице'}"
          >
            <template v-slot:item.read="{ item }">
              <v-simple-checkbox
                  :value="item.read"
                  @click.prevent="selectedOrderFromToggleRead = {id: item.id, status: item.read}; toggleReadDialog = true"
              ></v-simple-checkbox>
            </template>
            <template slot="no-data">
              Нет регистраций
            </template>
            <template v-slot:[`footer.page-text`]="items">
              {{ items.pageStart }} - {{ items.pageStop }} из {{ items.itemsLength }}
            </template>
          </v-data-table>
        </v-tab-item>

        <v-tab-item>
          <v-data-table
              :headers="headers"
              :items="orders"
              class="elevation-1 admin-table"
              :footer-props="{'items-per-page-text':'Заказов на странице'}"
              show-expand
              :expanded.sync="expanded"
          >
            <template v-slot:item.read="{ item }">
              <v-simple-checkbox
                  v-model="item.read"
                  @click.prevent="selectedOrderFromToggleRead = {id: item.id, status: item.read}; toggleReadDialog = true"
              ></v-simple-checkbox>
            </template>
            <template slot="no-data">
              Нет заказов
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="admin-table__td">
                <v-list class="admin-table__products-list">
                  <CustomCartAndOrderCard v-for="(el, index) of item.orderProducts" :key="'orderProduct-' + el.id"
                    :product="el.product" :category="el.product.category" :orderCount="el.count" :index="index"
                  ></CustomCartAndOrderCard>
                </v-list>
              </td>
            </template>
            <template v-slot:[`footer.page-text`]="items">
              {{ items.pageStart }} - {{ items.pageStop }} из {{ items.itemsLength }}
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
    <v-dialog v-model="toggleReadDialog" max-width="250">
      <v-card>
        <v-card-text class="d-flex flex-column">
          <p class="mt-4 text-center" style="font-weight: bold" v-if="selectedOrderFromToggleRead.status === false">Отметить прочитанным</p>
          <p class="mt-4 text-center" style="font-weight: bold" v-else>Отметить не прочитанным</p>
          <v-btn @click="toggleRead()">Подтвердить</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import CustomCartAndOrderCard from "@/components/CustomCartAndOrderCard";

import {mapGetters, mapState} from 'vuex'

export default {
  name: "AdminMails",
  components: {CustomCartAndOrderCard},
  data() {
    return {
      headers: [
        { text: 'Прочитано', value: 'read' },
        { text: 'Фамилия', value: 'secondName' },
        { text: 'Имя', value: 'name' },
        { text: 'Дата рождения', value: 'birthday' },
        { text: 'Отчество', value: 'surname' },
        { text: 'Номер телефона', value: 'phone' },
        { text: 'E-mail', value: 'email' },
        { text: 'Адрес', value: 'address' },
        { text: 'ИНН', value: 'TIN' },
        { text: 'Как узнали о нас?', value: 'hear' },
        { text: 'Дата регистрации', value: 'date' },
      ],
      expanded: [],
      toggleReadDialog: false,
      selectedOrderFromToggleRead: {
        id: null,
        status: false
      }
    }
  },
  computed: {
    ...mapState({
      registers: state => state.mails.registers,
      orders: state => state.mails.orders,
    }),
    ...mapGetters({
      unreadRegisters: "mails/getUnreadRegisters",
      unreadOrders: "mails/getUnreadOrders",
    }),
    activeTab: {
      get() {
        return Number(this.$route.query.tab)
      },
      set(newValue) {
        this.$router.replace({ name: "mails", query: {tab: newValue} })
      }
    }
  },
  methods: {
    toggleRead() {
      this.$store.dispatch('mails/toggleRead', {id: this.selectedOrderFromToggleRead.id, status: !this.selectedOrderFromToggleRead.status}).then(() => {
        this.toggleReadDialog = false
      })
    }
  }
}
</script>

<style lang="sass">
.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content
  box-shadow: unset
  td
    padding: 0 !important

.admin
  .v-slide-group__prev
    display: none !important
  .v-slide-group__next
    display: none !important
  &-mails
    &__tab
      text-transform: unset
      &:before
        display: none !important
  &-table
    background-color: #F8F8F8 !important
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15) !important
    border-radius: 12px

@media (max-width: 1263.99px)
  .admin
    &-table
      &__products-list
        background: none !important
      &__td
        display: flex
        flex-direction: column
        height: auto !important

@media (max-width: 959.98px)
  .admin
    .v-tabs-bar
      height: 40px !important
    &-mails
      &__tab
        height: 46px

@media (max-width: 599.98px)
  .admin
    &-mails
      &__tab
        height: 46px
        font-size: 12px
</style>
